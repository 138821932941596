exports.linkResolver = (doc) => {
  switch (doc.type) {
    case "standard_page":
      return `/${doc.uid}/`
    case "blog_post":
      return `/resources/${doc.uid}/`
    case "blog_portal":
      return `/resources/`
    case "blog_category":
      return `/resources/${doc.uid}/`
    default:
      if (!doc.uid) return "/"
      return doc.uid
  }
}
