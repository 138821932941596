import { linkResolver } from "./link-resolver"
import { lazy } from "react"

/**
 * Prismic preview configuration for each repository in your app. This set of
 * configuration objects will be used with the `PrismicPreviewProvider`
 * higher order component.
 *
 * If your app needs to support multiple Prismic repositories, add each of
 * their own configuration objects here as additional elements.
 *
 */
export const repositoryConfigs = [
  {
    repositoryName: "trev",
    linkResolver,
    componentResolver: {
      standard_page: lazy(() => import("../../src/templates/standardPage.js")),
      blog_post: lazy(() => import("../../src/templates/blogPost.js")),
      blog_portal: lazy(() => import("../../src/templates/blogPortal.js")),
      blog_category: lazy(() => import("../../src/templates/blogCategory.js")),
    },
  },
]
